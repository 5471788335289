import React from 'react'
import styled from '@emotion/styled'
import TitleWithDivider from '../components/TitleWithDivider'
import CustomRequest from '../components/customRequest/CustomRequest'
import RequestsTable from '../components/userRequests/RequestsTable'
import { useMyRequests } from '../hooks/useMyRequests'
import useTrackVirtualPageView from '../hooks/useTrackVirtualPageView'
import { VirtualPage } from '../types/VirtualPage'

const StyledTitleWithDiver = styled(TitleWithDivider)`
  margin-top: 20px;
`

const RequestsPage = () => {
  useTrackVirtualPageView(VirtualPage.REQUESTS)
  const { data, isLoading, refetch } = useMyRequests()

  const handleRequestSubmitted = async () => {
    await refetch()
  }

  return (
    <div>
      <div style={{ marginLeft: '5px', marginRight: '5px' }}>
        <CustomRequest onRequestSubmitted={handleRequestSubmitted} />
      </div>
      <StyledTitleWithDiver
        title="My Requests"
        titleCssProperties={{ paddingTop: '20px', marginBottom: '24px' }}
        displaySearchViewType={false}
      />
      <div style={{ padding: '10px 20px 20px 20px' }}>
        <RequestsTable isLoading={isLoading} mediaItems={data} />
      </div>
    </div>
  )
}

export default RequestsPage
