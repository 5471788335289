import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import { tableCellClasses } from '@mui/material/TableCell'
import { Components } from '@mui/material/styles'
import createTheme, { Theme } from '@mui/material/styles/createTheme'
import colors from './colors'

const font = "'Matter', 'Roboto Condensed', sans-serif"

let theme = createTheme({
  spacing: 2,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },
  palette: {
    background: {
      default: colors.background,
      paper: colors.white
    },
    text: {
      primary: colors.textStandard
    },
    primary: {
      main: colors.plex[100]
    },
    common: {
      plex: colors.plex[100],
      plexEighty: colors.plex[80],
      loginWrapperColor: colors.loginContainerColor,
      containerBody: colors.containerBody,
      sliderBackground: colors.sliderBackground,
      homePageTitles: colors.homePageTitles,
      plexOnline: colors.plexAvailability.online,
      plexOffline: colors.plexAvailability.offline,
      plexMaintenance: colors.plexAvailability.maintenance,
      requestedStatus: colors.requestStatuses.requested,
      rejectedStatus: colors.requestStatuses.rejected,
      approvedStatus: colors.requestStatuses.approved,
      availableStatus: colors.requestStatuses.available,
      requestStatusBackground: colors.requestStatuses.background,
      searchGridPosterTitle: colors.searchGridPosterTitle
    },
    success: {
      main: '#ff00d9'
    },
    error: {
      main: '#6600d9'
    }
  },
  typography: {
    fontFamily: font,
    h1: {
      fontSize: '40px',
      fontWeight: '500',
      lineHeight: '50px',
      color: colors.textHeading
    },
    h2: {
      fontSize: '32px',
      fontWeight: '500',
      lineHeight: '40px',
      color: colors.textHeading
    },
    h3: {
      fontSize: '26px',
      fontWeight: '500',
      lineHeight: '36px',
      color: colors.textHeading
    },
    h4: {
      fontSize: '20px',
      fontWeight: '500',
      lineHeight: '28px',
      color: colors.textHeading
    },
    h5: {
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '22px',
      color: colors.textHeading
    },
    inputLabel: {
      fontFamily: font,
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '18px',
      color: colors.inputText
    },
    inputText: {
      fontFamily: font,
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
      color: colors.inputText
    },
    helperText: {
      fontFamily: font,
      fontSize: '12px',
      fontWeight: '700',
      lineHeight: '16px',
      color: colors.error
    },
    body1: {
      fontFamily: font,
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      color: colors.textStandard
    },
    body2: {
      fontFamily: font,
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
      color: colors.textStandard
    },
    mobileSlideText: {
      fontFamily: font,
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '18px',
      color: colors.textStandard
    }
  }
})

const components: Components<Theme> = {
  // noinspection JSUnusedGlobalSymbols
  MuiMenu: {
    styleOverrides: {
      root: {
        '& .MuiPaper-root': {
          backgroundColor: colors.menuPanelBackground
        }
      }
    }
  },
  MuiChip: {
    styleOverrides: {
      root: {
        backgroundColor: colors.plex[100],
        color: colors.chip.text,
        ':hover': {
          background: colors.chip.hoverBackground
        },
        ':focus': {
          background: colors.chip.focusBackground,
          boxShadow: `0 0 0 3px ${theme.palette.common.plex}`
        },
        ':active': {
          background: colors.chip.activeBackground
        },
        ':disabled': {
          color: colors.chip.disabledText,
          background: colors.chip.disabledBackground
        },
        '& .MuiChip-icon': {
          color: colors.chip.icon
        }
      }
    }
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: { marginLeft: '0px' }
    }
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: colors.header
      }
    }
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: theme.palette.common.plex,
        '&.Mui-checked': {
          color: theme.palette.common.plex
        }
      }
    }
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        outline: 'none',
        backgroundColor: theme.palette.common.white,
        position: 'relative',
        borderRadius: '4px',
        fontSize: theme.typography.inputText.fontSize,
        lineHeight: theme.typography.inputText.lineHeight,
        width: '100%',
        '& .MuiInputBase-input': {
          fontSize: '16px' // Set the font size for the input
        }
      }
    }
  },
  MuiOutlinedInput: {
    defaultProps: {
      notched: false
    },
    styleOverrides: {
      notchedOutline: {
        borderColor: theme.palette.common.black
      },
      root: {
        [`& :hover .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: theme.palette.common.plex,
          borderWidth: '2px'
        },
        [`& .Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: theme.palette.common.plex
        },
        [`& .${outlinedInputClasses.input}`]: {
          color: theme.typography.inputText.color
        },
        [`&.${outlinedInputClasses.error} .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: colors.error
        }
      }
    }
  },
  MuiButton: {
    defaultProps: {
      disableTouchRipple: true,
      disableFocusRipple: true
    },
    styleOverrides: {
      contained: {
        fontSize: theme.typography.inputText.fontSize,
        fontWeight: 600,
        textTransform: 'none',
        borderRadius: '20px',
        height: '48px',
        padding: theme.spacing(6, 12),
        backgroundColor: theme.palette.common.plex,
        width: '100%',
        ':hover': {
          background: colors.buttons.hoverBackground
        },
        ':focus': {
          background: colors.buttons.focusBackground,
          boxShadow: `0 0 0 3px ${theme.palette.common.plex}`
        },
        ':active': {
          background: colors.buttons.activeBackground
        },
        ':disabled': {
          color: colors.buttons.disabledText,
          background: colors.buttons.disabledBackground,
          border: `3px solid ${colors.black}`
        }
      }
    }
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.common.plex,
        ':hover': {
          background: colors.buttons.hoverBackground
        },
        ':focus': {
          background: colors.buttons.focusBackground,
          boxShadow: `0 0 0 3px ${theme.palette.common.plex}`
        },
        ':active': {
          background: colors.buttons.activeBackground
        },
        ':disabled': {
          color: colors.buttons.disabledText,
          background: colors.buttons.disabledBackground
        }
      }
    }
  },
  MuiContainer: {
    styleOverrides: {
      root: {
        paddingLeft: '0px',
        paddingRight: '0px',
        '@media (min-width: 600px)': {
          paddingLeft: '0px',
          paddingRight: '0px'
        }
      }
    }
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        backgroundColor: colors.background,
        color: theme.palette.common.white,
        ':hover': {
          background: colors.plex[100]
        }
      }
    }
  },
  MuiTableHead: {
    styleOverrides: {
      root: {}
    }
  },
  MuiTableBody: {
    styleOverrides: {
      root: {}
    }
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        backgroundColor: colors.table.background,
        ['&:nth-of-type(odd)']: {
          backgroundColor: colors.table.backgroundAlt
        },
        ['&:nth-of-type(even)']: {
          backgroundColor: colors.table.background
        },
        ['&:last-child td, &:last-child th']: {
          border: 0
        }
      }
    }
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        border: 0,
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: colors.table.header,
          color: colors.table.headerTextColor
        }
      }
    }
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        color: colors.alertText,
        backgroundColor: colors.alertBackground,
        '& .MuiAlert-icon': {
          color: colors.error
        }
      }
    }
  }
}

theme = createTheme(theme, {
  components
})
export default theme
